import { MailberryLoader } from '@/components/display/loader/MailberryLoader';
import { displayErrorNotification } from '@/components/display/notifications';
import { postAuthOtpDemo, postAuthSignupDemo } from '@/services/api/auth';
import { useAuth } from '@/services/hooks/useAuth';
import { Anchor, Button, Group, Image, PinInput, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import styles from './SignUp.module.css';

export default function SignUp() {
  const location = useLocation();
  const { email } = location.state || {};

  const signUpMutation = useMutation({ mutationFn: postAuthOtpDemo });

  const [formStep, setFormStep] = useState(1);
  const [accountData, setAccountData] = useState(null);

  const handleSuccessfulStep1 = accountData => {
    setFormStep(2);
    setAccountData(accountData);
  };

  return (
    <>
      <Group justify='center' className={styles.rootBox}>
        <Stack align='center' justify='center'>
          <Stack mb='xl'>
            <Image src='/assets/mailberry-logo-form.svg' alt='Mailberry logo' width={180} height={40} />
            <Text c='dimmed' size='sm' mt='md'>
              Complete the form to see how Mailberry works
            </Text>
          </Stack>
          {formStep === 1
            ? <Step1 populatedEmail={email} onSuccess={handleSuccessfulStep1} initialValues={accountData} signUpMutation={signUpMutation} />
            : <Step2 accountData={accountData} goBack={() => setFormStep(1)} signUpMutation={signUpMutation} />}
        </Stack>
      </Group>
    </>
  );
}

const Step1 = ({ onSuccess, initialValues, signUpMutation, populatedEmail = '' }) => {
  const navigate = useNavigate();
  const formSchema = z.object({
    email: z.string().email('Invalid email').max(200),
    name: z.string().max(200),
    businessName: z.string().max(200),
    website: z.string(),
  });

  type FormValues = z.infer<typeof formSchema>;

  const form = useForm<FormValues>({
    validate: zodResolver(formSchema),
    initialValues: initialValues ? initialValues : {
      email: populatedEmail,
      name: '',
      businessName: '',
      website: '',
    },
  });

  const handleOnSubmit = async (values: FormValues) => {
    const email = values.email.trim().toLowerCase();
    try {
      await signUpMutation.mutateAsync({ email });
      onSuccess({
        email,
        name: values.name.trim().toLowerCase(),
        businessName: values.name.trim().toLowerCase(),
        website: values.name.trim().toLowerCase(),
      });
    } catch (e: any) {
      let message;
      if (e instanceof AxiosError) {
        message = e.response?.data.message;
      } else {
        message = e.message;
      }
      displayErrorNotification("Couldn't sign up", message, 5000);
    }
  };

  const goBack = () => {
    navigate('/login');
  };

  return (
    <>
      <MailberryLoader visible={signUpMutation.isLoading} />
      <form onFocus={() => signUpMutation.error} onSubmit={form.onSubmit(handleOnSubmit)}>
        <TextInput size='md' mb={20} label='Email' placeholder='Your email' required {...form.getInputProps('email')} />
        <TextInput size='md' mb={20} label='Name' placeholder='Your name' required {...form.getInputProps('name')} />
        <TextInput size='md' mb={20} label='Business name' placeholder='Your business name' required {...form.getInputProps('businessName')} />
        <TextInput size='md' mb={20} label='Website' placeholder='Your website' {...form.getInputProps('website')} />

        <Text fz={12} mb={20} c='dark'>
          By authenticating, you agree to Mailberry's{' '}
          <a href='https://mailberry.ai/terms/' target={'_blank'} rel={'noreferrer'} className={styles.link}>
            Terms of Service
          </a>{' '}
          and{' '}
          <a href='https://mailberry.ai/privacy/' target={'_blank'} rel={'noreferrer'} className={styles.link}>
            Privacy Policy
          </a>
        </Text>
        <Button fullWidth type='submit' loading={signUpMutation.isLoading} size='lg'>
          See Mailberry Demo
        </Button>
        <Group mt='md' justify='center' w='365px'>
          <Anchor size='lg' onClick={goBack}>
            Back
          </Anchor>
        </Group>

        <Group mt='md' justify='center'>
        </Group>
      </form>
    </>
  );
};

const Step2 = ({ accountData, goBack, signUpMutation }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const verifyAndCreateDemoAccountMutation = useMutation({ mutationFn: postAuthSignupDemo });
  const formSchema = z.object({
    code: z.string().length(6).regex(/^\d{6}$/),
  });

  type FormValues = z.infer<typeof formSchema>;

  const form = useForm<FormValues>({
    validate: zodResolver(formSchema),
    initialValues: {
      code: '',
    },
  });

  const [isResending, setIsResending] = useState(false);
  const handleResendCode = async () => {
    setIsResending(true);
    await signUpMutation.mutateAsync({ email: accountData.email });
    setIsResending(false);
  };

  const handleOnSubmit = async (values: FormValues) => {
    try {
      const { data } = await verifyAndCreateDemoAccountMutation.mutateAsync({ ...accountData, code: values.code.trim() });
      form.reset();
      const { message, error, accessToken } = data?.data;
      if (message && message === 'OK') {
        auth.setAccessToken(accountData.email, accessToken, true);
        navigate('/demo');
      } else if (error) {
        displayErrorNotification('Error', message, 5000);
      }
    } catch (e: any) {
      let message;
      if (e instanceof AxiosError) {
        message = e.response?.data.message;
      } else {
        message = e.message;
      }
      displayErrorNotification("Couldn't sign up", message, 5000);
    }
  };

  return (
    <>
      <MailberryLoader visible={verifyAndCreateDemoAccountMutation.isLoading} />

      <form onSubmit={form.onSubmit(handleOnSubmit)}>
        <Stack align='center' justify='center' gap={'md'}>
          <Text ta='center'>
            Check your inbox at:<Text inherit span fw={700}>{` "${accountData.email}"`}</Text>
          </Text>
          <PinInput
            placeholder='-'
            length={6}
            size='md'
            {...form.getInputProps('code')}
            autoFocus
            onKeyDown={e => {
              if (e.key === 'Enter') {
                form.onSubmit(handleOnSubmit)();
              }
            }}
          />
          <Button fullWidth mt='md' type='submit' size='lg'>
            Confirm
          </Button>
          <Button fullWidth mt='md' variant='outline' onClick={handleResendCode} size='lg' loading={isResending}>
            Resend Code
          </Button>
          <Group mt='md' justify='center' w='365px'>
            <Anchor size='lg' onClick={goBack}>
              Back
            </Anchor>
          </Group>
        </Stack>
      </form>
    </>
  );
};
