import MBMonitor from '@/assets/icons/MBMonitor';
import MBSmartphone from '@/assets/icons/MBSmartphone';
import { parseStyleString } from '@/lib/utils';
import useCheckMobileScreen from '@/services/hooks/useMobileScreen';
import AppState from '@/services/state/AppState';
import { REVIEW_STATUS } from '@/sharedTypes';
import { Affix, Box, Button, Center, Group, Stack, Text, Transition } from '@mantine/core';
import { DemoDraft, DemoReview, Draft, Review } from '@prisma/client';
import { useTour } from '@reactour/tour';
import parse, { Element } from 'html-react-parser';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { HTMLRendered } from './BodyParsed';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import { EmailPreviewFooter } from './EmailPreviewFooter';
import { MailberryNode } from './MailberryNode';

export interface EmailReviewProps {
  standalonePage?: boolean;
  onClose?: () => void;
}
interface EmailReviewCore {
  draftId: string;
  standalonePage?: boolean;
  emailReview: (Review | DemoReview) & { businessAddress: string; };
  emailPreview: Draft | DemoDraft;
  onApprove: (emailReviewId: string) => void;
  onRequestChanges: (emailReviewId: string, content?: Record<string, any>) => void;
  isLoading: boolean;
}

const EmailReviewCore = ({ draftId, standalonePage, emailReview, emailPreview, onApprove, onRequestChanges, isLoading }: EmailReviewCore) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedNodeComments, setSelectedNodeComments] = useState<string[]>([]);

  const [showCommentList, setShowCommentList] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);

  const isMobile = useCheckMobileScreen();
  const [showDesktopVersion, setShowDesktopVersion] = useState(true);
  const [localCommentsCounter, setLocalCommentsCounter] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const appState = AppState.useContainer();
  const [styles, setStyles] = useState({
    editorColor: '',
    fontColor: '',
    borderWidth: '',
    borderColor: '',
    borderRadius: '',
    backgroundColor: '',
  });
  const resetSelection = () => {
    console.log('reset Selectiomn');
    setSelectedNodeId(null);
    setShowCommentInput(false);
    setShowCommentList(false);
    setTimeout(() => {
      setSelectedNodeComments([]);
    }, 300);
    // tour: go to next step after send the comment
    if (appState.userAndCustomer.isInTour) {
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
        setIsOpen(true);
      }, 500);
    }
  };

  // force reload htmlParsed memo  (comments count bubbles)
  useEffect(() => {
    const localComments = localStorage.getItem('comments_' + emailReview?.id);
    if (localComments) {
      setLocalCommentsCounter(prev => prev + 1);
    }
  }, [emailReview?.id]);

  // verify draft has changued and discard old comments
  useEffect(() => {
    if (emailPreview?.id && emailReview?.id && localCommentsCounter !== 0) {
      const bodyLength = emailPreview.body.length;

      const previousBodyLength = localStorage.getItem('bodyLength_' + draftId + '_' + emailReview?.id);
      if (previousBodyLength) {
        // compare
        if (Number(previousBodyLength) !== bodyLength) {
          localStorage.clear();
          setLocalCommentsCounter(prev => 0);
        }
      }
      localStorage.setItem('bodyLength_' + draftId + '_' + emailReview?.id, String(bodyLength));
    }
  }, [emailPreview?.id, emailReview?.id, localCommentsCounter]);

  const getComments = (nodeId: string): string[] => {
    let comments = [];
    const content = emailReview?.content as Record<string, any>;
    if (content.comments) {
      comments = content.comments[nodeId];
    }

    const localComments = localStorage.getItem('comments_' + emailReview?.id);
    if (localComments) {
      const parsedComments = JSON.parse(localComments);
      const currentNodeComments = parsedComments[nodeId];
      if (currentNodeComments) {
        comments.push(...currentNodeComments);
      }
    }

    return comments;
  };

  const handleShowCommentList = (nodeId: string) => {
    setSelectedNodeId(nodeId);
    if (nodeId !== selectedNodeId) {
      setSelectedNodeComments(getComments(nodeId));
    }

    if (isMobile) {
      setShowCommentInput(false);
    } else {
      setShowCommentInput(true);
    }

    setShowCommentList(true);
  };

  const toggleCommentInput = (nodeId?: string) => {
    if (isOpen && currentStep !== 0) return;
    if (!nodeId) {
      resetSelection();
      return;
    }

    const comments = getComments(nodeId) || [];
    if (nodeId === selectedNodeId) {
      setSelectedNodeId(null);
      setShowCommentInput(false);
      setSelectedNodeComments([]);
    } else {
      setSelectedNodeId(nodeId);
      setShowCommentInput(true);
      setSelectedNodeComments(comments);
    }
  };

  useEffect(() => {
    if (selectedNodeId && (isMobile || isOpen)) {
      setTimeout(() => {
        scrollToSelectedNode(selectedNodeId);
      }, 100);
    }
  }, [isKeyboardOpen, selectedNodeId]);

  const scrollToSelectedNode = (nodeId: string, behavior: ScrollBehavior = 'smooth') => {
    const selectedElement = document.getElementById(nodeId);
    const containerScroll = containerRef.current.parentElement;
    if (selectedElement && containerScroll) {
      const elementRect = selectedElement.getBoundingClientRect();
      const containerRect = containerScroll.getBoundingClientRect();

      const offsetTop = (elementRect.top - (containerRect.height * 0.2)) + containerScroll.scrollTop;
      // const viewportHeight = window.innerHeight;
      // const additionalOffset = isKeyboardOpen ? viewportHeight * 0.4 : 0;

      containerScroll.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleAddComment = async (content: string) => {
    console.log('handleAddComment', selectedNodeId, content);
    if (selectedNodeId) {
      const localComments = JSON.parse(localStorage.getItem('comments_' + emailReview?.id)!) || {};

      if (localComments[selectedNodeId]) {
        localComments[selectedNodeId].push(content);
      } else {
        localComments[selectedNodeId] = [content];
      }

      localStorage.setItem('comments_' + emailReview?.id, JSON.stringify(localComments));
      setLocalCommentsCounter(prev => prev + 1);
    }
  };

  // https://www.npmjs.com/package/html-react-parser
  const htmlParsed = useMemo(() => {
    // Returns a react element by each domNode so we can add a onClick event to it
    if (emailReview && emailPreview) {
      let body = '';
      if (emailReview.status === REVIEW_STATUS.APPROVED || emailReview?.status === REVIEW_STATUS.CHANGES_REQUESTED) {
        body += emailReview.body;
        const styles = emailReview.styles as Record<string, any>;
        setStyles({
          editorColor: styles?.editorColor || '',
          fontColor: styles?.fontColor || '',
          borderWidth: styles?.borderWidth || '',
          borderColor: styles?.borderColor || '',
          borderRadius: styles?.borderRadius || '',
          backgroundColor: styles?.backgroundColor || '',
        });
      } else {
        body += emailPreview.body;
        const styles = emailPreview.styles as Record<string, any>;
        setStyles({
          editorColor: styles?.editorColor || '',
          fontColor: styles?.fontColor || '',
          borderWidth: styles?.borderWidth || '',
          borderColor: styles?.borderColor || '',
          borderRadius: styles?.borderRadius || '',
          backgroundColor: styles?.backgroundColor || '',
        });
      }

      return parse(body, {
        replace: (domNode, position) => {
          if (domNode instanceof Element && domNode.type === 'tag') {
            const uniqueId = `unique_id_${domNode.name}_${position}`;

            if (domNode.attribs.style) {
              const styleDict = parseStyleString(domNode.attribs.style);
              domNode.attribs.style = styleDict as any;
            }

            if (domNode.attribs.class) {
              domNode.attribs.className = domNode.attribs.class;
              delete domNode.attribs.class;
            }

            if (domNode.name === 'hr') {
              return <hr key={uniqueId} {...domNode.attribs} />;
            }

            const isSelected = selectedNodeId === uniqueId;
            const comments = getComments(uniqueId);

            const classNames = [];
            if (isSelected) classNames.push('commentNumber');

            return (
              <div
                onClick={() => toggleCommentInput(uniqueId)}
                className={classNames.join(' ')}
                style={{
                  display: 'flex',
                  position: 'relative',
                  backgroundColor: isSelected ? '#A5B4FC' : '',
                  cursor: 'pointer',
                }}
              >
                <MailberryNode
                  id={uniqueId}
                  domNode={domNode}
                />
                {(comments?.length > 0)
                  && (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        handleShowCommentList(uniqueId);
                      }}
                      color='indigo'
                      style={{
                        position: 'absolute',
                        right: 0,
                      }}
                    >
                      {comments?.length}
                    </Button>
                  )}
              </div>
            );
          }
        },
      });
    }
  }, [emailPreview?.body, emailReview?.body, selectedNodeId, localCommentsCounter]);

  const subjectAndPreheaderParsed = useMemo(() => {
    if (emailReview && emailPreview) {
      const formatStringToHtml = (key: string, value: string) => `<span><strong>${key}</strong> ${value}</span>`;

      let body = '';
      if (emailReview.status === REVIEW_STATUS.APPROVED || emailReview?.status === REVIEW_STATUS.CHANGES_REQUESTED) {
        body = formatStringToHtml('Subject line : ', emailReview.subject);

        if (emailReview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailReview.preHeader || '');
        }
      } else {
        body = formatStringToHtml('Subject line : ', emailPreview.subject);
        if (emailPreview.preHeader) {
          body += formatStringToHtml('Preview text : ', emailPreview.preHeader || '');
        }
      }

      body += !isMobile ? '<hr style="margin: 0.5em 0em 1em 0em">' : '<hr>';

      return parse(body, {
        replace: (domNode, position) => {
          if (domNode instanceof Element && domNode.type === 'tag') {
            const uniqueId = `unique_id_${domNode.name}_${position}`;

            if (domNode.attribs.style) {
              const styleDict = parseStyleString(domNode.attribs.style);
              domNode.attribs.style = styleDict as any;
            }

            if (domNode.attribs.class) {
              domNode.attribs.className = domNode.attribs.class;
              delete domNode.attribs.class;
            }

            if (domNode.name === 'hr') {
              return <hr key={uniqueId} {...domNode.attribs} />;
            }

            const isSelected = selectedNodeId === uniqueId;
            const comments = getComments(uniqueId);

            return (
              <div
                onClick={() => toggleCommentInput(uniqueId)}
                className={!isSelected ? 'commentNumber' : ''}
                style={{
                  display: 'flex',
                  position: 'relative',
                  backgroundColor: isSelected ? '#A5B4FC' : '',
                }}
              >
                <MailberryNode
                  id={uniqueId}
                  domNode={domNode}
                />
                {(comments?.length > 0)
                  && (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        if (isMobile) {
                          handleShowCommentList(uniqueId);
                        }
                      }}
                      style={{
                        position: 'absolute',
                        top: '2px',
                        right: 0,
                      }}
                    >
                      {comments?.length}
                    </Button>
                  )}
              </div>
            );
          }
        },
      });
    }
  }, [emailReview?.subject, emailReview?.preHeader, emailPreview?.subject, emailPreview?.preHeader, selectedNodeId, localCommentsCounter]);

  if (!emailReview) {
    return (
      <Group justify='center' style={{ width: '100%' }}>
        <Text size='lg'>Review not found!</Text>
      </Group>
    );
  }
  const { setCurrentStep, setIsOpen, currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 1) {
      appState.userAndCustomer.setIsInTour(true);
      setCurrentStep(currentStep + 1);
      setSelectedNodeId('unique_id_mailberryimage_0');
      setTimeout(() => {
        scrollToSelectedNode('unique_id_mailberryimage_0', 'auto');
      }, 50);

      // setTimeout(()=>{
      //   setCurrentStep(currentStep + 2);
      // },4000)
    }

    if (appState.userAndCustomer.isInTour && currentStep === 3) {
      setIsOpen(false);
      setShowCommentInput(true);
    }

    if (appState.userAndCustomer.isInTour && currentStep === 5) {
      setSelectedNodeId('unique_id_p_4');
      setCurrentStep(currentStep + 1);
      setIsOpen(true);
    }

    if (currentStep === 7) {
      setSelectedNodeId(null);
    }
  }, [currentStep, appState.userAndCustomer.isInTour]);

  return (
    <Box
      style={{
        height: '100%',
        // !standalonePage ? '100%' : isMobile ? '100%' : '80dvh', // height: standalonePage? isMobile ? '100%' : '80%' : isMobile ? '91dvh': '80dvh'
        // display: 'flex',
        // justifyContent: standalonePage ? 'space-evenly' : '',
        // alignItems: standalonePage ? 'center' : '',
        // gap: isMobile ? 0 : '2rem',
      }}
      p={isMobile ? 0 : 4}
      ref={containerRef}
    >
      <Stack
        align='center'
        gap={10}
        w='100%'
      >
        <Group
          justify='center'
          display={isMobile ? 'none' : 'flex'}
          // gap={4}
          // m={'sm'}
        >
          <Button
            onClick={() => {
              setShowDesktopVersion(true);
            }}
            variant={showDesktopVersion ? 'light' : 'white'}
          >
            <MBMonitor size={24} color={showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
          <Button
            onClick={() => {
              setShowDesktopVersion(false);
            }}
            variant={!showDesktopVersion ? 'light' : 'white'}
          >
            <MBSmartphone size={24} color={!showDesktopVersion ? '#3B82F6' : '#6B7280'} />
          </Button>
        </Group>

        <Box
          style={{
            // borderRadius: !isMobile && showDesktopVersion ? '1em' : '0',
            // boxShadow: !isMobile ? '1px 1px 24px rgba(0,0,0,0.1)' : 'none',
            width: isMobile ? '100%' : showDesktopVersion ? '760px' : '390px',
          }}
        >
          <Box fz={'xl'} p={'0px 16px'}>
            <HTMLRendered html={subjectAndPreheaderParsed!} />
          </Box>
          <Box
            bg={showDesktopVersion && !isMobile ? styles.backgroundColor : ''}
            p={!isMobile && showDesktopVersion ? '24px 16px' : '0px'}
            mih={'300px'}
          >
            <Box className='tour-second-step' onScroll={() => console.log(88)}>
              <div
                className={(!isMobile && showDesktopVersion ? 'editor-preview-desktop' : 'editor-preview-mobile') + ' '}
                style={{
                  backgroundColor: styles.editorColor,
                  color: styles.fontColor,
                  // borderWidth: styles.borderWidth,
                  // borderColor: styles.borderColor,
                  borderRadius: showDesktopVersion && !isMobile ? styles.borderRadius : '',
                  border: styles.borderWidth && showDesktopVersion && !isMobile ? `${styles.borderWidth}px solid ${styles.borderColor}` : '',
                }}
              >
                <HTMLRendered html={htmlParsed!} />
                <EmailPreviewFooter businessMailAddress={emailReview?.businessAddress!} />
              </div>
              <Center
                style={{
                  color: styles.fontColor,
                }}
                p='14px 48px 40px'
                fz={'sm'}
              >
                Powered by
                <span
                  style={{
                    color: '#3B82F6',
                    textDecoration: 'underline',
                  }}
                >
                  <img
                    width='75'
                    height='18'
                    style={{ width: 75, height: 18, verticalAlign: 'text-bottom', display: 'inline-block', marginLeft: 5 }}
                    src='http://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png'
                    alt='Mailberry logo'
                  />
                </span>
              </Center>
            </Box>
          </Box>
        </Box>

        {emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW
          ? (
            <Group
              justify='center'
              grow
              w={isMobile ? '100%' : showDesktopVersion ? '760px' : '390px'}
              py={10}
              px={!isMobile && showDesktopVersion ? '24px' : '16px'}
            >
              <Button
                className={'tour-third-step'}
                onClick={() => onRequestChanges(emailReview?.id, emailReview?.content as Record<string, any>)}
                style={{ height: '3rem' }}
                color={'orange'}
                disabled={isLoading || !localCommentsCounter}
                variant='outline'
              >
                <Text>Request changes</Text>
              </Button>
              <Button
                className={'tour-fifth-step'}
                onClick={() => onApprove(emailReview?.id)}
                style={{ height: '3rem' }}
                color={'green'}
                disabled={localCommentsCounter > 0 || isLoading || (isOpen && currentStep === 2)}
                variant='outline'
              >
                <Text>Approve email as is</Text>
              </Button>
            </Group>
          )
          : (
            <Group
              justify='center'
              m={10}
              p={10}
              h={'3em'}
              style={{ border: '1px solid #E5E7EB' }}
              bg={'#F2F2F2'}
            >
              <Text size={'1.25em'} fw={700} c={'#858585'}>
                {emailReview.status.charAt(0).toUpperCase() + emailReview.status.slice(1).toLocaleLowerCase().replaceAll('_', ' ')}
              </Text>
            </Group>
          )}

        {isMobile
          && (
            <>
              {showCommentList
                ? (
                  <Affix zIndex={1000} position={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Transition
                      duration={300}
                      transition='slide-up'
                      mounted={showCommentList}
                    >
                      {styles => (
                        <Box
                          style={{
                            height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
                            marginTop: 'env(safe-area-inset-top)',
                            marginBottom: 'env(safe-area-inset-bottom)',
                            width: '100%',
                            ...styles,
                          }}
                        >
                          <CommentList onClose={resetSelection} comments={selectedNodeComments} mobile />
                        </Box>
                      )}
                    </Transition>
                  </Affix>
                )
                : (
                  <Transition
                    duration={300}
                    transition='rotate-left'
                    mounted={showCommentInput && emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}
                  >
                    {styles => (
                      <CommentInput
                        onClose={resetSelection}
                        handleAddComment={handleAddComment}
                        isVisible={true}
                        styles={{ ...styles, position: 'sticky', bottom: 0 }}
                        isMobile
                      >
                      </CommentInput>
                    )}
                  </Transition>
                )}
            </>
          )}
      </Stack>
      {!isMobile
        && (
          <Stack
            w='400px'
            // miw={400}
            // maw={800}
            mah='%90'
            mt={10}
            top={containerRef.current?.parentElement.parentElement.parentElement.parentElement.scrollTop || 0 + 100}
            style={{
              position: 'fixed',
              right: '5%',
              boxShadow: '0 0 24px rgba(0,0,0,0.1)',
            }}
          >
            <CommentList onClose={resetSelection} comments={selectedNodeComments} />
            <CommentInput onClose={resetSelection} handleAddComment={handleAddComment} isVisible={showCommentInput && emailReview?.status === REVIEW_STATUS.READY_FOR_REVIEW}></CommentInput>
          </Stack>
        )}
    </Box>
  );
};

export default EmailReviewCore;
